
import { SampleDialog } from '@wenjuan/business'
export default {
  description: '用来统一入口文件，调用参数',
  name: 'CommonSampleDialog',
  components: {
    SampleDialog,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    sampleDialogInfo() {
      return this.$store.state.sampleDialogInfo
    },
  },
  watch: {},
  methods: {
    closeSampleDialog() {
      this.$store.commit('setSampleDialog', {
        visible: false, // 显示
      })
    },
  },
}
